import React, { useState } from "react";
import { Nav_Links } from "./Nav_Links";
import Logo from "../../assets/Images/logo_mysa.png";
import { Link, NavLink } from "react-router-dom";
import facebook from "../../assets/svg/facebook.svg";
import bijli from "../../assets/svg/bijli.svg";
import instagram from "../../assets/svg/instagram.svg";
import twitter from "../../assets/svg/twitter.svg";
import { MobileNav } from "./MobileNav";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoCall, IoMenu } from "react-icons/io5";

import { RiMenuFoldFill } from "react-icons/ri";

const SocialIcons = [facebook, bijli, instagram, twitter];

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuIndex, setSubMenuIndex] = useState("");

  return (
    <header>
      <nav>
        <div className="flex md:flex-row flex-col justify-center items-center gap-1 bg-[#353335] py-6 open-sans-family">
          <div className="flex sm:text-[1.8rem] text-[1.4rem] font-normal text-[#9D9D9D] items-center gap-4">
            <IoCall size={22} /> Call us now for exclusive deals:
          </div>
          <Link
            to={`tel:+919834554238`}
            className="sm:text-[1.8rem] text-[1.4rem] font-normal text-[#c79678]"
          >
            +91-9834554238
          </Link>
        </div>
        <div className=" bg-white py-5">
          <div className="max-width flex justify-between items-center">
            {/* Logo*** */}
            <Link to={"/"} className="w-[200px] h-[40px]">
              <img loading="lazy" src={Logo} className="w-full h-full" />
            </Link>

            {/* Navbar Links***** */}
            <ul className="hidden lg:flex items-center gap-12 open-sans-family">
              {Nav_Links.map((Links, index) => {
                return (
                  <li key={index} className="relative">
                    <NavLink
                      className="text-[1.58rem] text-[#2c2c2c] font-normal capitalize  hover:text-[#c79678] transition-all flex gap-2 items-center"
                      to={Links.src}
                      onClick={() => {
                        setSubMenuOpen(!subMenuOpen);
                        setSubMenuIndex(index);
                      }}
                    >
                      {Links.linkName} {Links.isArrow && <IoMdArrowDropdown />}
                    </NavLink>
                    {Links.subLinks && subMenuOpen && index == subMenuIndex && (
                      <ul className="absolute top-14 left-0 bg-[#FFFFFF] flex flex-col  z-10 w-[180px] border border-gray-300 rounded-md">
                        {Links.subLinks.map((sl) => {
                          return (
                            <Link
                              to={`/city${sl.src}`}
                              className="text-black text-[1.6rem] font-normal hover:text-[#c79678] hover:bg-gray-200 transition-all px-6 py-2 capitalize"
                              onClick={() => {
                                setSubMenuOpen(false);
                              }}
                            >
                              {sl.subLinkName}{" "}
                            </Link>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
              <li className="text-[1.6rem] bg-[#C79678] text-white px-8 py-3 rounded-lg capitalize">
                <Link to={"/Partner"}>Partner with us</Link>
              </li>
            </ul>

            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="lg:hidden border border-[#c79678] rounded-md"
            >
              <IoMenu size={30} className="text-[#c79678]" />
            </div>
          </div>
        </div>
      </nav>
      <MobileNav open={open} setOpen={setOpen} />
    </header>
  );
};

export default Navbar;
