import React from "react";
import { Link } from "react-router-dom";

const Card3 = ({ Details }) => {
  return (
    <div>
      <div>
        <img
          src={Details.Image}
          alt="influener-img"
          className="w-full h-full"
        />
      </div>
      <div className="flex flex-col gap-2 justify-center items-center mt-8">
        <h2 className="text-[2.3rem] txt-black font-medium">{Details.Name}</h2>
        <p className="text-[1.8rem] lora-family italic font-normal">
          {Details.Para}
        </p>
        <Link
          to={Details.Instagram}
          className="text-[#A77251] text-[1.6rem] open-sans-family"
        >
          View More
        </Link>
      </div>
    </div>
  );
};

export default Card3;
