import React from "react";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <>
      <section className="mt-10">
        <div className="max-width flex lg:flex-row flex-col gap-16 min-h-[600px]">
          <div className="lg:w-[60%]">
            <h2 className="md:text-[3.3rem] md:leading-[4rem] text-[2.5rem] leading-[3rem] font-medium">
              Feel free to drop us a message
            </h2>
            <p className="md:text-[1.6rem] text-[1.4rem] font-normal text-[#808080] open-sans-family mt-2">
              Do you have anything in your brain to let us know? Kindly don't
              falter to connect to us through our contact form.
            </p>
            <form
              action=""
              className="flex flex-col gap-4 mt-8 open-sans-family"
            >
              <div>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Name"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>
              <div>
                <input
                  type="number"
                  name=""
                  id=""
                  placeholder="Phone Number"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>
              <div>
                <textarea
                  type="text"
                  rows={8}
                  placeholder="Message"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>

              <div>
                <button className="common-btn w-full !text-[1.8rem]">
                  Submit Now
                </button>
              </div>
            </form>
          </div>

          <div className="lg:w-[40%] flex flex-col gap-5 lg:mt-40">
            <h2 className="md:text-[3.3rem] text-[2.5rem] font-medium">
              Get in touch
            </h2>
            <p className="md:text-[1.7rem] text-[1.4rem] font-normal text-[#808080] open-sans-family">
              Office no 7 , second floor, gnp, galaxy industrial and commercial
              hub, Waldhuni, Ambernath, Ulhasnagar, Maharashtra 421501
            </p>
            <div className="text-[1.7rem] font-normal text-[#808080] open-sans-family">
              Call Us: <Link className="text-[#c79678]">+91-9834554238</Link>
            </div>
            <div className="text-[1.7rem] font-normal text-[#808080] open-sans-family">
              Email: <Link className="text-[#c79678]">mysastays@gmail.com</Link>
            </div>
          </div>
        </div>
        {/* Google Map******* */}
        <div className="mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.9581118058!2d76.76289349687472!3d28.644285696143115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1712055514616!5m2!1sen!2sin"
            width="100%"
            height="600"
            allowFullScreen
            loading="lazy"
            referrerPolicy=""
          />
        </div>
      </section>
    </>
  );
};
