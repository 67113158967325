import React from "react";
import { Link } from "react-router-dom";

export const Card1 = ({ Details, Position }) => {
  return (
    <div className="hover:scale-105 transition-all duration-500">
      <Link to={`/rooms/${Details.Heading}`}>
        <div>
          <div className="w-[100%]">
            <img
              src={Details.Image}
              alt="floor-img"
              className="w-full h-full rounded-lg"
            />
          </div>
          <div className="mt-4 px-4 flex flex-col gap-2">
            <h2 className="md:text-[2.8rem] text-[2rem] font-medium txt-black">
              {Details.Heading}
            </h2>

            <p
              className={`md:text-[1.6rem] text-[1.4rem] font-normal open-sans-family txt-gray-secondary ${
                Position ? "text-center" : "text-start"
              }`}
            >
              {Details.Para}
            </p>

            <div className="flex justify-between items-center mt-5">
              <h3 className="text-[2rem] lora-family txt-black font-bold flex gap-4 items-center">
                ₹{Details.Price} /-
                <span className="text-[1.5rem] txt-gray-secondary font-normal line-through">
                  ₹ 1200
                </span>
              </h3>

              <div>
                <button className="common-btn !py-3 sm:!px-24 !px-10 open-sans-family">
                  Detials
                </button>
              </div>
            </div>

            <p className="text-[1.5rem] txt-gray-secondary font-normal open-sans-family">
              Per Night
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
