import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Header/Navbar";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import { Contact } from "./pages/Contact/Contact";
import About from "./pages/About/About";
import { Privacy } from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import Property from "./pages/Property/Property";
import { RoomDetails } from "./pages/RoomDetails/RoomDetails";
import Event from "./pages/EventsCelebration/Event";
import PartnerWithUS from "./pages/Partnerus/PartnerWithUS";

function App() {
  return (
    <>
      {/* <div className="scroll-watcher"></div> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/city/:name" element={<Property />} />
        <Route path="/rooms/:name" element={<RoomDetails />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Events" element={<Event />} />
        <Route path="/Partner" element={<PartnerWithUS />} />

        {/* <Route path="/Blogs" element={<Blog />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Terms" element={<Terms />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
