import React from "react";
import roomdetailimg1 from "../../assets/Images/roomdetailimg1.png";
import roomdetailimg2 from "../../assets/Images/roomdetailimg2.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import clientImg1 from "../../assets/Images/client-img1.png";
import { FaWhatsapp } from "react-icons/fa";
import { PiDotsNineFill } from "react-icons/pi";

import img from "../../assets/Images/ac.png";
import Card2 from "../../components/Cards/Card2";

const Clients = [
  {
    Image: clientImg1,
    Heading: "Amalfi",
    Para: `We bunch of school friends stayed there for a day.
    Lovely and serene property.
    Well maintained and tidy. The bunglow is pretty spacious and can accomodate a good number of people.
    The caretaker is also very caring. The food prepared had a homely touch and tasted good.
    Overall a decent stay and would recommend it.`,
  },
  {
    Image: clientImg1,
    Heading: "Abhijeet",
    Para: `We bunch of school friends stayed there for a day.
    Lovely and serene property.
    Well maintained and tidy. The bunglow is pretty spacious and can accomodate a good number of people.
    The caretaker is also very caring. The food prepared had a homely touch and tasted good.
    Overall a decent stay and would recommend it.`,
  },
  {
    Image: clientImg1,
    Heading: "Divyanshu",
    Para: `We bunch of school friends stayed there for a day.
    Lovely and serene property.
    Well maintained and tidy. The bunglow is pretty spacious and can accomodate a good number of people.
    The caretaker is also very caring. The food prepared had a homely touch and tasted good.
    Overall a decent stay and would recommend it.`,
  },
  {
    Image: clientImg1,
    Heading: "kc",
    Para: `We bunch of school friends stayed there for a day.
    Lovely and serene property.
    Well maintained and tidy. The bunglow is pretty spacious and can accomodate a good number of people.
    The caretaker is also very caring. The food prepared had a homely touch and tasted good.
    Overall a decent stay and would recommend it.`,
  },
];

export const RoomDetails = () => {
  return (
    <section className="layout-section">
      <div className="max-width ">
        <div className="md:grid grid-cols-2 gap-2 hidden">
          <div>
            <img src={roomdetailimg1} alt="" className="w-full h-auto" />
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <img src={roomdetailimg2} alt="" className="w-full h-full" />
            </div>
            <div>
              <img src={roomdetailimg2} alt="" className="w-full h-full" />
            </div>
            <div>
              <img src={roomdetailimg2} alt="" className="w-full h-full" />
            </div>
            <div className="relative">
              <img src={roomdetailimg2} alt="" className="w-full h-full" />

              <div className="absolute bottom-2 right-2 bg-white px-5 py-2 cursor-pointer text-[1.5rem] font-normal rounded-md flex justify-center items-center gap-2 open-sans-family">
                <PiDotsNineFill size={16} /> Show All Photos
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden">
          <Swiper
            loop={true}
            autoplay={{
              delay: 8000,
            }}
            pagination={{
              el: "#bullets-Portfolio",
            }}
            navigation={{
              prevEl: ".prev-btn",
              nextEl: ".next-btn",
            }}
            modules={[Pagination, Autoplay, Navigation]}
            breakpoints={{
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
          >
            {new Array(5).fill("").map((details) => {
              return (
                <SwiperSlide>
                  <div>
                    <img src={roomdetailimg1} alt="" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="flex gap-5 lg:flex-row flex-col items-start mt-5">
          <div className="lg:w-[70%] w-[100%]">
            <h2 className="md:text-[3.8rem] text-[2.5rem] txt-black font-semibold">
              Zen Villa
            </h2>
            <ul className="flex gap-2 items-center md:text-[1.7rem] text-[1.4rem] text-[#808080] open-sans-family">
              <li>3 Bedrooms</li>
              <li className="list-disc list-inside">1 Kitchen</li>
              <li className="list-disc list-inside">10 Guest</li>
              <li className="list-disc list-inside">Pool</li>
            </ul>
            <hr className="mt-5" />

            <div className="mt-5">
              <h3 className="md:text-[2.5rem] text-[1.8rem] txt-black font-medium">
                Amenities offers:
              </h3>
              <div className="mt-5">
                <Swiper
                  loop={true}
                  slidesPerView={2}
                  spaceBetween={10}
                  autoplay={{
                    delay: 8000,
                  }}
                  modules={[Pagination, Autoplay, Navigation]}
                  breakpoints={{
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 15,
                    },
                  }}
                >
                  {new Array(10).fill("").map(() => {
                    return (
                      <SwiperSlide>
                        <div className="border border-gray-300 border-b-4 border-b-[#C79678] rounded-lg py-3">
                          <img
                            src={img}
                            alt=""
                            className="md:w-[70px] w-[35px] mx-auto"
                          />

                          <h3 className="text-center text-[1.5rem] txt-black lora-family">
                            Ac in bedroom
                          </h3>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>

            <div className="mt-16">
              <ul className="flex gap-4 overflow-auto open-sans-family font-normal">
                <li className="border border-gray-600 px-10 py-4 md:text-[1.6rem] text-[1.4rem] rounded-md cursor-pointer hover:bg-[#c79678] hover:text-white hover:border-[#c79678] flex-none">
                  The Space
                </li>
                <li className="border border-gray-600 px-10 py-4 md:text-[1.6rem] text-[1.4rem] rounded-md cursor-pointer hover:bg-[#c79678] hover:text-white hover:border-[#c79678] flex-none">
                  Meals
                </li>
                <li className="border border-gray-600 px-10 py-4 md:text-[1.6rem] text-[1.4rem] rounded-md cursor-pointer hover:bg-[#c79678] hover:text-white hover:border-[#c79678] flex-none">
                  Cancellation Pollicy
                </li>
                <li className="border border-gray-600 px-10 py-4 md:text-[1.6rem] text-[1.4rem] rounded-md cursor-pointer hover:bg-[#c79678] hover:text-white hover:border-[#c79678] flex-none">
                  House Rules
                </li>
              </ul>
            </div>

            <div className="mt-8">
              <h3 className="text-[2.5rem] txt-black font-medium">
                Description:
              </h3>
              <p className="text-[1.6rem] text-[#7b7b7b] open-sans-family">
                Where luxury meets nature's embrace – your tranquil 3BHK villa
                in Lonav
              </p>
              <div className="text-[1.5rem] font-bold cursor-pointer open-sans-family">
                <button className=" underline inline-block mt-4">
                  Show More
                </button>
                <span className="ml-2">&gt;</span>
              </div>
            </div>

            <div className="mt-16">
              <h3 className="text-[2.5rem] txt-black font-medium">
                Customer Reviews:
              </h3>
              <div>
                <div className="md:mt-10 mt-10">
                  <Swiper
                    loop={true}
                    autoplay={{
                      delay: 4000,
                    }}
                    pagination={{
                      el: "#bullets-Portfolio",
                    }}
                    navigation={{
                      prevEl: ".prev-btn",
                      nextEl: ".next-btn",
                    }}
                    modules={[Pagination, Autoplay, Navigation]}
                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {Clients.map((details) => {
                      return (
                        <SwiperSlide>
                          <Card2 Details={details} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>

                  {/* swiper button and pagination customization***** */}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-[30%] w-[100%] sticky top-5">
            <div className="border border-gray-300 rounded-md px-6 py-8 open-sans-family">
              <div>
                <div className="flex gap-2 items-center">
                  <p className="text-[2.3rem] text-black">₹20,000</p>
                  <span className="line-through text-[1.6rem] text-[#808080]">
                    ₹22,000 / Night
                  </span>
                </div>
                <p className="text-[1.6rem] text-[#808080]">Starting Price</p>
              </div>

              {/* check in out  ***** */}
              <div className="mt-8">
                <p className="text-[1.6rem] text-[#808088]">
                  Check-In / Check-Out
                </p>
                <div className="flex mt-3">
                  <input
                    type="date"
                    name=""
                    id=""
                    className="w-full outline-none border border-gray-200 py-2 px-3 text-[1.6rem]"
                  />
                  <input
                    type="date"
                    name=""
                    id=""
                    className="w-full outline-none border border-gray-200 py-2 px-3 text-[1.6rem]"
                  />
                </div>
              </div>

              <div className="mt-5">
                <p className="text-[1.6rem] text-[#808088]">Guest</p>
                <div className="mt-5">
                  <select
                    name=""
                    id=""
                    className="w-full border border-gray-200 px-5 py-2 outline-none rounded-md text-[1.6rem]"
                  >
                    <option value="">1</option>
                    <option value="">2</option>
                    <option value="">3</option>
                    <option value="">4</option>
                    <option value="">5</option>
                  </select>
                </div>
              </div>

              <div className="mt-6">
                <button className="common-btn w-full !py-4 flex justify-center items-center gap-3">
                  Get in touch <FaWhatsapp size={25} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
