import React from "react";
import "./Work.scss";
import { Link } from "react-router-dom";
import Image from "../../assets/Images/Category-bannercoorg.png";
import img1 from "../../assets/Images/room-0f3197757b8dc4ba2030d9adf6563131.png";
import { Card1 } from "../../components/Cards/Card1";

const Details = [
  {
    Image: img1,
    Heading: "Coorg",
    Para: "Escape to your 2BHK villa in Karnataka, where a private pool and a game-filled living room create the perfect blend of relaxation and entertainment.",
    Price: 12000,
  },
  // {
  //   Image: img1,
  //   Heading: "Electric Permits and Inspections",
  //   Para: "All our electrical services are subcontracted to Licensed Electrical Contractors to light up your project safely and brilliantly. We make sure all your spaces are uniquely inviting.",
  //   Price: 2500,
  // },
  // {
  //   Image: img1,
  //   Heading: "Millwork and Furniture",
  //   Para: "Crafting character and charm in every detail. Our honing and polishing techniques bring out the best in every square foot, ensuring a transformation that delights our clients.",
  //   Price: 2500,
  // },
];

const Property = () => {
  return (
    <>
      <section
        className="h-[150px] flex items-center justify-center"
        style={{
          backgroundImage: `url(${Image})`,
        }}
      >
        <div>
          <h2 className="text-center text-[4.3rem] text-white">Coorg</h2>

          <p className="text-center text-[1.7rem] text-[#c79678] font-normal lora-family">
            Home &gt; <Link className="text-white">Coorg</Link>
          </p>
        </div>
      </section>

      <section className="layout-section">
        <div className="max-width">
          <h2 className="md:text-[3.3rem] text-[2.5rem] txt-black">
            Rooms And Villa
          </h2>
          <p className="text-[1.7rem] text-[#808080] open-sans-family">Coorg</p>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 mt-24">
            {Details.map((details) => {
              return <Card1 Details={details} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Property;
