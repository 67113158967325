import React from "react";
import bannerImg from "../../../assets/Images/HomeBannerImg1.png";
import bannerImg2 from "../../../assets/Images/HomeBannerImg2.png";
import bannerImg3 from "../../../assets/Images/HomeBannerImg3.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const BannerImg = [bannerImg, bannerImg2, bannerImg3];

export const HomeBanner = () => {
  return (
    <>
      <div>
        <div>
          <Swiper
            loop={true}
            slidesPerView={1}
            autoplay={{
              delay: 8000,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Autoplay, Navigation]}
          >
            {BannerImg.map((Image) => {
              return (
                <SwiperSlide>
                  <div
                    className="md:h-[610px] h-[200px]"
                    style={{
                      backgroundImage: `url(${Image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                    }}
                  >
                    {/* <img
                      src={Image}
                      alt="banner-photo"
                      className="w-full h-full"
                    /> */}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};
