import React from "react";
import PrivacyImg from "../../assets/Images/ContactImage.png";
import Banner from "../../components/PageBanner/Banner";

const Policy = [
  {
    Heading: "Lorem Ipsum is simply dummy text",
    Para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    Heading: "Lorem Ipsum",
    Para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
  },
  {
    Heading: "Lorem Ipsum",
    Para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
  },
  {
    Heading: "Lorem Ipsum",
    Para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
  },
  {
    Heading: "Lorem Ipsum",
    Para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,",
  },
];

const PersonalInformation = [
  "Name and Gender",
  "Phone Number",
  "E-mail Address",
  "Contact Address",
  "Password",
  "Financial Information: Such as Bank Account, Credit Card, Debit Card Details, or other payment instrument information used for payment processing.",
  "Physical, Physiological, and Mental Health Condition Other Details: Such as Biometric Information, Sexual Orientation, Medical Records, and History.",
];

const List = [
  `  Trusted third parties, such as accountants, credit card processors, hotels, and government authorities, may access your information only to the extent necessary for them to perform specific services.`,
  `If we restructure, sell, or lease our business, any rights related to your information will be transferred to the new entity, owner, or operator. We pledge to take reasonable measures to safeguard your privacy in such transitions.`,
  `Information sharing may occur when required to aid in investigating, preventing, or addressing unlawful activities, suspected fraud, potential threats to safety, security, or rights of the website, unauthorized use or misuse of the website, violations of our terms and conditions, or to establish our legal rights in connection with claims or legal proceedings.`,
  `Special circumstances include responding to subpoenas and court orders or cooperating with authorized agencies in accordance with the 2009 Information Technology (Procedure and Safeguards for Interception, Monitoring, and Decryption of Information) Rules for information interception, monitoring, or decryption. Collaboration with authorized agencies for traffic data or information collection, as per the Information Technology (Procedure and Safeguard for Monitoring and Collecting Traffic Data or Information) Rules, 2009.`,
  `To facilitate or outsource specific aspects of our business, product, and service operations, we may engage internal service providers, known as third parties. In such cases, we may directly provide some of your personal information to these internal service providers. They are bound by confidentiality agreements and legal restrictions, ensuring the information's use is restricted to facilitating the outsourced website-related operation, unless you have expressly agreed to or granted permission for additional uses.`,
];

export const Privacy = () => {
  return (
    <>
      <section className="layout-section">
        <h1 className="text-[5.3rem] font-medium txt-black text-center">
          Privacy Policy
        </h1>
        <div className="max-width !mt-32 pb-10 open-sans-family">
          <div>
            <p className="text-[1.6rem] font-normal txt-black">
              As a Mysa Stays user, you entrust us with sensitive information,
              including your phone details, address, and bank account numbers.
              Our Privacy Policy articulates Mysa Stays' guidelines and
              practices regarding the collection and utilization of your
              personal information. This privacy statement outlines the data
              collected during our routine business operations and its potential
              usage. By accessing or using this website, you explicitly consent
              to the use and disclosure of your personal information as
              described in this Privacy Policy. The terms specified in the Terms
              and Conditions of Use are also integral to and enforceable in this
              Privacy Policy. Please note that this Privacy Policy is subject to
              modification without prior notice to website visitors or users. We
              advise you to regularly review this policy for any updates or
              alterations. Explore further to learn about the comprehensive
              measures in place to secure your personal data.
            </p>
          </div>
          <div>
            <h2 className="text-[2.8rem] font-medium txt-black mt-12 ">
              Personal Information
            </h2>

            <div className="flex flex-col gap-16 mt-10">
              <div>
                <h2 className="text-[2.4rem] font-medium txt-black ">
                  Personal information refers to any data capable of identifying
                  you, including
                </h2>
                <ul className="list-decimal list-inside px-3 flex flex-col gap-2 mt-2 text-[1.6rem] font-normal txt-black open-sans-family">
                  {PersonalInformation.map((info) => (
                    <li className="text-[1.6rem]">{info}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Information collected and its uses
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  While visiting our website, you typically are not obliged to
                  share personal information. However, if you wish to make
                  bookings, we will need details such as your name, phone
                  number, email, billing, and bank account information. We
                  always allow you the choice to withhold information by not
                  using specific features, and we clarify when information is
                  optional or necessary. Accuracy in the information provided is
                  essential, and we disclaim liability for its accuracy or
                  consequences. Certain data may be automatically collected
                  based on your website interactions, aiding our internal
                  research on user demographics, interests, and behaviour. We
                  track website usage and client traffic patterns to enhance our
                  services. Rest assured, we only collect necessary data crucial
                  for the services we provide on our site, prioritizing your
                  privacy and security.
                </p>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Cookies
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  are small files that are downloaded to your computer's hard
                  drive, enhancing our service delivery. They are utilized on
                  select web pages to analyse page navigation, gauge promotional
                  impact, and a sense of security and trust. Cookies enable
                  personalized content delivery and browser memory for
                  user-specific data, reducing password entry frequency. Most
                  cookies are session-based and are promptly removed from your
                  hard drive when your session ends. You have the option to
                  decline cookie usage if your browser permits. However, this
                  may limit access to certain website services, necessitating
                  more frequent login information input during your session.
                  Please be aware that on some website pages, third parties
                  occasionally deploy cookies or similar data collection tools,
                  and we lack control over their cookie usage.
                </p>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Utilization of the Information We Collect:
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  Rest assured, we strictly employ the personal information you
                  provide only when necessary and relevant to our routine
                  business operations. This includes processing website
                  reservations, enhancing service quality, and establishing
                  contact via phone and email as required. In some cases, we may
                  share this data with reputable third parties. Furthermore, we
                  may use this pertinent information to communicate about your
                  reservation, inform you of advertised services on our website,
                  and enforce the Terms and Conditions of Use, resolve disputes,
                  and address issues.
                </p>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  Additionally, this information assists us in consumer interest
                  in our services, notifying you about offers and updates both
                  online and offline, personalizing your experience, detecting
                  and safeguarding against errors, fraud, and other unlawful
                  activities, as well as fulfilling other purposes disclosed at
                  the time of data collection. This data also aids in enhancing
                  our promotional and marketing efforts, analyzing website
                  usage, and refining and customizing our website's services and
                  content.
                </p>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  These functions contribute to an improved website experience,
                  tailored to your needs, ensuring a seamless, secure, and
                  personalized user experience on the site.
                </p>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black">
                  Information Sharing with Third Parties:
                </h2>
                <h3 className="text-[2.4rem] font-medium txt-black">
                  Under specific circumstances, your information may be shared
                  with third parties:
                </h3>
                <ul className="flex flex-col gap-3 list-decimal list-inside mt-5 px-5 text-[1.6rem] font-normal txt-black open-sans-family">
                  {List.map((info) => (
                    <li>{info}</li>
                  ))}
                  <li>
                    <span className="text-[1.6rem] font-bold txt-black">
                      Disclosure of Information:
                    </span>
                    <p>
                      Advertisements: The website may feature advertisements
                      from third parties, and when you provide information to
                      these advertisers while they are displayed on the website,
                      both we and the advertiser simultaneously collect the
                      data. The advertiser utilizes the information according to
                      their standard privacy policy, and we handle the data in
                      line with our privacy statement's provisions. It's
                      important to review their policies before sharing the
                      mentioned information, as we have no control over the
                      privacy practices of these advertisers or third parties.
                      We do not accept responsibility for the data you provide
                      to these advertisers or the data they collect from you.
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Links to Other Websites:
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  The website may contain links to other websites that gather
                  user data. We disclaim any responsibility for the content or
                  privacy policies of any linked websites.
                </p>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Promotions and Updates:
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  By providing your email address during a reservation, you
                  agree to receive communications from us regarding offers and
                  updates related to the website and the services offered. We
                  may monitor the website features you use most frequently to
                  evaluate your usage of our services. This helps us present the
                  most relevant and useful information about our services and
                  the website. If you ever change your preferences and no longer
                  wish to receive communications from us, you can opt out or
                  unsubscribe by using the unsubscribe option on our website or
                  by clicking the provided link in our email communications.
                </p>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Security for Your Information:
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  We are committed to safeguarding the data you share with us.
                  We have implemented various security measures, including
                  administrative, technological, and physical safeguards, to
                  protect the information you provide. For instance, we encrypt
                  sensitive information you provide when using our system and
                  limit access to your personal data to individuals with a
                  legitimate business need. Your data security is our top
                  priority.
                </p>
              </div>
              <div>
                <h2 className="text-[2.8rem] font-medium txt-black ">
                  Security Measures:
                </h2>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  To ensure the confidentiality of your information, we commit
                  to employing reasonable security practices, modern Internet
                  security methods, and technologies in compliance with the
                  Information Technology Act of 2000 and its associated
                  regulations. These measures are in place to prevent
                  unauthorized access, maintain data accuracy, and ensure the
                  appropriate use of information.
                </p>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  Access to personal information is strictly limited to
                  employees, contractors, and agents who require it for the
                  operation, development, or enhancement of our services. These
                  individuals are bound by confidentiality obligations, and any
                  violation of these commitments may result in severe
                  consequences, including termination and criminal prosecution.
                </p>
                <p className="text-[1.6rem] font-normal txt-black open-sans-family mt-2">
                  While we implement industry best practices to protect your
                  privacy, it is important to note that we cannot guarantee that
                  none of your personal information or private communications
                  will ever be shared in a manner not covered by this Privacy
                  Policy. For instance, we might be compelled to provide
                  personal information to the government or third parties under
                  certain circumstances. Additionally, unauthorized interception
                  or access to transmissions or private communications, as well
                  as users' misuse of collected personal information from the
                  website, are potential risks despite our security efforts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
