import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav_Links } from "./Nav_Links";
import { IoMdArrowDropdown } from "react-icons/io";

export const MobileNav = ({ open, setOpen }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuIndex, setSubMenuIndex] = useState("");
  return (
    <div
      className="fixed top-0 right-0 bg-white w-full h-full z-10"
      style={{
        height: open ? "1200px" : "0",
        transition: "all 1s",
        overflow: "hidden",
      }}
    >
      <h2
        onClick={() => {
          setOpen(!open);
        }}
        className="text-black text-[2rem] font-semibold text-end px-5 py-2 open-sans-family"
      >
        X
      </h2>
      <ul className="flex flex-col gap-10 px-5 ">
        {Nav_Links.map((Links, index) => {
          return (
            <li key={index} className="relative open-sans-family">
              <NavLink
                className="text-[1.7rem] text-black font-normal capitalize  hover:text-[#c79678] transition-all flex items-center gap-1"
                to={Links.src}
                onClick={() => {
                  setSubMenuOpen(!subMenuOpen);
                  setSubMenuIndex(index);
                  if (!Links.isArrow) {
                    setOpen(false);
                  }
                }}
              >
                {Links.linkName} {Links.isArrow && <IoMdArrowDropdown />}
              </NavLink>
              {Links.subLinks && subMenuOpen && index == subMenuIndex && (
                <ul className="absolute top-14 left-0 bg-[#FFFFFF] flex flex-col gap-4 px-6 py-3 z-10 w-full border border-gray-300 rounded-md">
                  {Links.subLinks.map((sl) => {
                    return (
                      <Link
                        to={`/city${sl.src}`}
                        className="text-black text-[1.6rem] font-normal hover:text-[#c79678] transition-all"
                        onClick={() => {
                          setSubMenuOpen(false);
                          setOpen(false);
                        }}
                      >
                        {sl.subLinkName}
                      </Link>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
        <li className="text-[1.7rem] bg-[#C79678] text-white px-8 py-3 rounded-lg capitalize open-sans-family">
          <Link
            to={"/Partner"}
            onClick={() => {
              setOpen(!open);
            }}
          >
            Partner with us
          </Link>
        </li>
      </ul>
    </div>
  );
};
