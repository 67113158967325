import React from "react";
import Logo from "../../assets/Images/logo_mysa.png";

import { Link, NavLink } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

const QuickLinks = [
  {
    linkName: "Events & Celebrations",
    src: "/Events",
  },
  {
    linkName: "partner with us",
    src: "/Partner",
  },
  {
    linkName: "about us",
    src: "/About",
  },
  {
    linkName: "Privacy Policy",
    src: "/Privacy",
  },
  {
    linkName: "Terms & Condition",
    src: "/Terms",
  },
];

const Footer = () => {
  return (
    <footer className="bg-[#242424] layout-section !pb-6">
      <div className="max-width">
        {/* Logo and Links Area****** */}
        <div className="grid lg:grid-cols-3 gap-8 lg:px-10 px-0">
          <div className="flex flex-col gap-6 lg:ml-20">
            <div className="w-[260px]">
              <img src={Logo} alt="logo_mysa" className="w-full h-full" />
            </div>
            <div>
              <p className="md:text-[1.6rem] text-[1.4rem] text-[#a8a8a8] open-sans-family">
                Elevating stays, one experience at a time
              </p>
              <div className="flex gap-5 mt-5">
                <Link className="w-[30px] h-[30px] border rounded-full flex items-center justify-center">
                  <FaFacebookF className="text-white text-[1.6rem]" />
                </Link>
                <Link className="w-[30px] h-[30px] border rounded-full flex items-center justify-center">
                  <FaInstagram className="text-white text-[1.6rem]" />
                </Link>
                <Link className="w-[30px] h-[30px] border rounded-full flex items-center justify-center">
                  <FaYoutube className="text-white text-[1.6rem]" />
                </Link>
              </div>
            </div>
          </div>

          {/* Qucik Links***** */}
          <div className="flex lg:justify-center">
            <div className="flex flex-col gap-6">
              <h2 className="text-[2.3rem] font-medium text-white">
                Qucik Links
              </h2>
              <ul className="flex flex-col gap-4 open-sans-family">
                {QuickLinks.map((Links, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        className="md:text-[1.6rem] text-[1.4rem] font-normal capitalize text-[#a8a8a8] hover:text-[#8E7861]"
                        to={Links.src}
                      >
                        {Links.linkName}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Contact Info ***** */}
          <div className="flex flex-col gap-6">
            <h2 className="text-[2.3rem] font-medium text-white">
              Contact Info
            </h2>
            <ul className="flex flex-col gap-4 open-sans-family">
              <li className="md:text-[1.6rem] text-[1.4rem] font-normal text-[#a8a8a8] hover:text-[#C79678]">
                <Link to={"tel:+919834554238"}>+91-9834554238</Link>
              </li>
              <li className="md:text-[1.6rem] text-[1.4rem] font-normal text-[#a8a8a8] hover:text-[#C79678]">
                Office no 7 , second floor, gnp, galaxy industrial and
                commercial hub, Waldhuni, Ambernath, Ulhasnagar, Maharashtra
                421501
              </li>
              <li className="md:text-[1.6rem] text-[1.4rem] font-normal text-[#a8a8a8] hover:text-[#C79678]">
                <Link to="mailto:mysastays@gmail.com">mysastays@gmail.com</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Line******** */}
        <hr className="mt-16 " />

        {/* copyright**** */}
        <div className="flex justify-center mt-10 open-sans-family">
          <p className="text-[1.6rem] text-[#a8a8a8] font-normal">
            © Copyright Mysa Stays 2021-2024. Developed By{" "}
            <Link
              to="https://eazotel.com"
              target="_blank"
              className="underline"
            >
              Eazotel
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
