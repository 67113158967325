import React from "react";
import { HomeBanner } from "./components/HomeBanner";
import HeroSection from "../../components/HeroSections/HeroSection";

import SectionHeading from "../../components/SectionHeading/SectionHeading";
import ContactSection from "./components/ContactSection";
import Testimonal from "../../components/Testimonal/Testimonal";
import BrandSection from "./components/BrandSection";

import VideoSection from "./components/Video";
import PortfolioSection from "./components/PortfolioSection";
import Vacciation from "../../assets/Images/intro_img.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import Coorg from "../../assets/Images/Category-coorg.png";
import Nashik from "../../assets/Images/Category-nashik.png";
import Goa from "../../assets/Images/Category-goa.png";
import Khopoli from "../../assets/Images/Category-khopoli.png";
import Lonavla from "../../assets/Images/Category-lonavala.png";
import Karjat from "../../assets/Images/Category-karjat.png";

import { MdArrowRightAlt } from "react-icons/md";
import PropertiesSection from "./components/PropertiesSection";
import Card2 from "../../components/Cards/Card2";

import clientImg1 from "../../assets/Images/clientimg1.png";
import clientImg2 from "../../assets/Images/clientimg2.png";
import clientImg3 from "../../assets/Images/clientimg3.png";
import clientImg4 from "../../assets/Images/clientimg4.png";

import Book from "../../assets/Images/Book.png";
import Card3 from "../../components/Cards/Card3";

import InfluenceImg from "../../assets/Images/influncer-995fcf567583a6f40a854a0d7ffa3d6c.png";
import influncer2 from "../../assets/Images/ifluencer2.png";
import influncer3 from "../../assets/Images/influncer3.png";
import influncer4 from "../../assets/Images/influncer4.png";

import place from "../../assets/svg/place.svg";
import support from "../../assets/svg/support.svg";
import valformoney from "../../assets/svg/valueformoney.svg";
import view from "../../assets/svg/view.svg";
import privacy from "../../assets/svg/privacy.svg";
import booking from "../../assets/svg/booking.svg";

const Location = [
  {
    Image: Coorg,
    Name: "Coorg",
  },
  {
    Image: Nashik,
    Name: "Nashik",
  },
  {
    Image: Goa,
    Name: "Goa",
  },
  {
    Image: Khopoli,
    Name: "Khopoli",
  },
  {
    Image: Lonavla,
    Name: "Lonavla",
  },
  {
    Image: Karjat,
    Name: "Karjat",
  },
];

const ChooseUs = [
  {
    Heading: "A place for everyone",
    Para: "We believe in curating a space for everyone, which matches & compliment their style",
    Icon: place,
  },
  {
    Heading: "Dedicated support",
    Para: "Elevate your vacation experience featuring our dedicated support at every step by our team, ensuring your journey is seamless and stress-free.",
    Icon: support,
  },
  {
    Heading: "Value for money",
    Para: "Enjoy undiscounted services and amenities without compromising on your budget. ",
    Icon: valformoney,
  },
  {
    Heading: "Amazing View",
    Para: "We bring you a fantastic collection of private villas in tranquil locations that are ideal for every occasion.",
    Icon: view,
  },
  {
    Heading: "Home Like Privacy",
    Para: "Our goal is to provide you with an unforgettable experience , and ensure you have all the needed comforts to make you feel at home",
    Icon: privacy,
  },
  {
    Heading: "Easy Booking Process",
    Para: "We believe in superior service which makes your holiday exceptional through our personalized attention to detail.",
    Icon: booking,
  },
];

const Numbers = [
  {
    number: "10k",
    para: "Happy Guests Hosted",
  },
  {
    number: "4.8",
    para: `Average
    Ratings`,
  },
  {
    number: "10+",
    para: `
    Handpicked
    Properties`,
  },
  {
    number: "1000+",
    para: `
    Successfull
    Bookings`,
  },
];

const Clients = [
  {
    Image: clientImg1,
    Heading: "Ohana",
    Para: `Villa was at the walking distance from lonavala station , The interior was very aesthetic, the pool was also clean and hygienic we also loved the service, care taker was available for every thing, we would also like to book villa from them in
    future.
    `,
    Name: "yash singh",
  },
  {
    Image: clientImg2,
    Heading: "Blanco",
    Para: `Really good villa, enjoyed our stay there. `,
    Name: "aditi",
  },
  {
    Image: clientImg3,
    Heading: "Zen Villa",
    Para: `Calm and beautiful villa I have ever been to. My kids and family enjoyed a lot. Swimming pool area is too good and clean.
    `,
    Name: "yash sharma",
  },
  {
    Image: clientImg4,
    Heading: "Amara Villa",
    Para: `The place was really nice, surrounded by nature and calm. Even though this villa doesn’t have a pool like other adjacent villas still we enjoyed a lot. Once you reach there the place was pleasant. Everything was clean.`,
    Name: "yash singh",
  },
];

const Influencer = [
  {
    Name: "Rity In Nashik",
    Para: "BELLA VUE",
    Instagram: "",
    Image: InfluenceImg,
  },
  {
    Name: "Griva In Goa",
    Para: "Blanco",
    Instagram: "",
    Image: influncer2,
  },
  {
    Name: "Kshama In lonavala",
    Para: "Blanco",
    Instagram: "",
    Image: influncer3,
  },
  {
    Name: "Maahie in Karjat",
    Para: "Ninad homestay",
    Instagram: "",
    Image: influncer4,
  },
  // {
  //   Name: "Rity In Nashik",
  //   Para: "Bella Vue",
  //   Instagram: "",
  //   Image: InfluenceImg,
  // },
];

const Home = () => {
  return (
    <main>
      {/* Home Banner**** */}
      <section>
        <HomeBanner />
      </section>

      {/* Top Location ***** */}
      <section className="layout-section">
        <div className="max-width">
          <h2 className="text-center txt-black sm:text-[3.3rem] text-[2rem] font-medium capitalize">
            Top Location
          </h2>
          <div className="md:mt-20 mt-10">
            <Swiper
              slidesPerView={2}
              loop={true}
              autoplay={{
                delay: 4000,
              }}
              pagination={{
                el: "#bullets-Portfolio",
              }}
              navigation={{
                prevEl: ".prev-btn",
                nextEl: ".next-btn",
              }}
              modules={[Pagination, Autoplay, Navigation]}
              breakpoints={{
                768: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
            >
              {Location.map((details) => {
                return (
                  <SwiperSlide>
                    <div className="px-6 py-4">
                      <div className="w-[90px] mx-auto">
                        <img
                          src={details.Image}
                          alt="location-image"
                          className="w-full h-full"
                        />
                      </div>
                      <h2 className="txt-black open-sans-family font-medium text-center text-[1.8rem] mt-3">
                        {details.Name}
                      </h2>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {/* swiper button and pagination customization***** */}
          </div>
        </div>
      </section>

      {/* Property section****** */}
      <section className="layout-section">
        <PropertiesSection />
      </section>

      {/* why choose us***** */}
      <section className="layout-section bg-[#3B2E39]">
        <div className="max-width ">
          <div className="flex flex-col items-center gap-3 justify-center">
            <h2 className="md:text-[4.3rem] text-[2.5rem] font-medium text-white">
              Why choose us
            </h2>
            <p className="md:text-[1.6rem] text-[1.4rem] text-center text-[#d4d4d4] open-sans-family">
              Your journey is our journey. Book with Mysa Stays, and let's
              create travel experiences that leave you with a smile in your
              heart and a story in your soul.
            </p>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-20 md:gap-28 gap-10">
            {ChooseUs.map((details) => {
              return (
                <div>
                  <div className="w-[80px] mx-auto">
                    <img
                      src={details.Icon}
                      alt="client-images"
                      className="w-full h-full"
                    />
                  </div>
                  <h2 className="text-center text-[#C79678] md:text-[2.8rem] text-[2rem] font-medium">
                    {details.Heading}
                  </h2>
                  <p className="text-center text-[#C79678] md:text-[1.8rem] text-[1.4rem] lora-family font-normal mt-3">
                    {details.Para}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="layout-section">
        <div className="max-width grid md:grid-cols-2 gap-5 items-center md:!mt-32">
          <div className="flex flex-col gap-5">
            <h2 className="txt-black lg:text-[4.3rem] lg:leading-[5rem] md:text-[3rem] md:leading-[3.8rem] text-[2.5rem] leading-[3rem] font-normal w-[80%]">
              Discover the difference of{" "}
              <span className="text-[#c79678]">choosing us</span> for your ideal
              vacation
            </h2>
            <p className="md:text-[2.3rem] text-[1.4rem] font-medium txt-gray-primary open-sans-family">
              We stand out with our handpicked properties, personalized service,
              and a commitment to making your vacation truly exceptional.
            </p>
          </div>
          <div>
            <img src={Vacciation} alt="" className="w-full h-full" />
          </div>
        </div>
      </section>

      {/* Count Section****** */}
      <section className="layout-section">
        <div className="max-width grid lg:grid-cols-4 sm:grid-cols-2 gap-8 sm:justify-center border-t py-20">
          {Numbers.map((details) => {
            return (
              <div className="flex items-center gap-4 sm:justify-center">
                <h2 className="txt-black-secondary md:text-[5.3rem] text-[3rem] font-medium lora-family">
                  {details.number}
                </h2>
                <p className="md:text-[1.8rem] text-[1.4rem] text-[#c79678] font-normal w-[35%]">
                  {details.para}
                </p>
              </div>
            );
          })}
        </div>
      </section>

      <section className="layout-section bg-[#C79678]">
        <div className="max-width">
          <h2 className="text-center font-medium text-white md:text-[3.8rem] text-[2.5rem]">
            What Our Clients Says
          </h2>

          <div className="md:mt-20 mt-10">
            <Swiper
              loop={true}
              autoplay={{
                delay: 4000,
              }}
              pagination={{
                el: "#bullets-Portfolio",
              }}
              navigation={{
                prevEl: ".prev-btn",
                nextEl: ".next-btn",
              }}
              modules={[Pagination, Autoplay, Navigation]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {Clients.map((details) => {
                return (
                  <SwiperSlide>
                    <Card2 Details={details} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {/* swiper button and pagination customization***** */}
          </div>
        </div>
      </section>

      <section
        className="layout-section md:h-[500px] h-[350px] flex items-center"
        style={{
          backgroundImage: `url(${Book})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="flex flex-col md:gap-14 gap-8 items-center justify-center max-w-[900px] mx-auto">
          <h2 className="md:text-[4.8rem] text-[2.4rem] text-center md:leading-[5rem] leading-[3rem] text-white">
            Ready to Book? Reach Us Today for Instant Reservations!
          </h2>
          <div className="flex md:flex-row flex-col gap-4 text-white md:text-[2.1rem] text-[1.5rem] lora-family italic font-normal">
            <div>
              <p>Call Us : +91-9834554238 </p>
            </div>
            <span className="hidden md:block">/</span>
            <div>
              <p>Email : mysastays@gmail.com</p>
            </div>
          </div>

          <div>
            <button className="common-btn open-sans-family border-2 border-white md:!text-[1.8rem] !uppercase">
              Book Now
            </button>
          </div>
        </div>
      </section>

      <section className="layout-section">
        <div className="max-width">
          <div className="md:mt-20 mt-10">
            <Swiper
              loop={true}
              autoplay={{
                delay: 4000,
              }}
              pagination={{
                el: "#bullets-Portfolio",
              }}
              navigation={{
                prevEl: ".prev-btn",
                nextEl: ".next-btn",
              }}
              modules={[Pagination, Autoplay, Navigation]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {Influencer.map((details) => {
                return (
                  <SwiperSlide>
                    <Card3 Details={details} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {/* swiper button and pagination customization***** */}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
