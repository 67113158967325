import React from "react";
import { HomeBanner } from "../Home/components/HomeBanner";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Card2 from "../../components/Cards/Card2";

import clientImg1 from "../../assets/Images/clientimg1.png";
import clientImg2 from "../../assets/Images/clientimg2.png";
import clientImg3 from "../../assets/Images/clientimg3.png";
import clientImg4 from "../../assets/Images/clientimg4.png";

const Clients = [
  {
    Image: clientImg1,
    Heading: "Ohana",
    Para: `Villa was at the walking distance from lonavala station , The interior was very aesthetic, the pool was also clean and hygienic we also loved the service, care taker was available for every thing, we would also like to book villa from them in
      future.
      `,
    Name: "yash singh",
  },
  {
    Image: clientImg2,
    Heading: "Blanco",
    Para: `Really good villa, enjoyed our stay there. `,
    Name: "aditi",
  },
  {
    Image: clientImg3,
    Heading: "Zen Villa",
    Para: `Calm and beautiful villa I have ever been to. My kids and family enjoyed a lot. Swimming pool area is too good and clean.
      `,
    Name: "yash sharma",
  },
  {
    Image: clientImg4,
    Heading: "Amara Villa",
    Para: `The place was really nice, surrounded by nature and calm. Even though this villa doesn’t have a pool like other adjacent villas still we enjoyed a lot. Once you reach there the place was pleasant. Everything was clean.`,
    Name: "yash singh",
  },
];

export default function Event() {
  return (
    <>
      <section>
        <HomeBanner />
      </section>

      <section className="layout-section">
        <h2 className="text-center md:text-[3.3rem] text-[2.5rem] font-medium txt-black">
          Get in touch
        </h2>
        <p className="md:text-[1.6rem] text-[1.4rem] text-center txt-gray-secondary open-sans-family">
          Do you have anything in your brain to let us know? Kindly don't falter
          to connect to us through our contact form.
        </p>
        <div className="max-w-[900px] px-5 mx-auto mt-10">
          <form
            action=""
            className="flex flex-col gap-8 w-full open-sans-family"
          >
            <div className="flex sm:flex-row flex-col gap-6 ">
              <input
                type="text"
                className="w-full outline-none text-[1.6rem] border border-gray-400 px-5 font-medium rounded-lg py-4"
                placeholder="Name"
              />
              <input
                type="text"
                className="w-full outline-none text-[1.4rem] border border-gray-400 px-5 font-medium rounded-lg py-4"
                placeholder="Phone Number"
              />
            </div>
            <div className="flex gap-6 sm:flex-row flex-col">
              <input
                type="text"
                className="w-full outline-none text-[1.6rem] border border-gray-400 px-5 font-medium rounded-lg py-4"
                placeholder="Email"
              />
              <input
                type="text"
                className="w-full outline-none text-[1.6rem] border border-gray-400 px-5 font-medium rounded-lg py-4"
                placeholder="Destination"
              />
            </div>
            <div className="flex gap-6 sm:flex-row flex-col">
              <input
                type="text"
                className="w-full outline-none text-[1.6rem] border border-gray-400 px-5 font-medium rounded-lg py-4"
                placeholder="No of guest"
              />
              <select
                type="text"
                className="w-full text-gray-500 outline-none text-[1.6rem] border border-gray-400 px-5 font-medium rounded-lg py-4"
                placeholder="Phone Number"
              >
                <option disabled selected>
                  Select Type Of Event
                </option>
                <option>Select type of event</option>
                <option>Select type of event</option>
                <option>Select type of event</option>
                <option>Select type of event</option>
                <option>Select type of event</option>
              </select>
            </div>
            <div className="flex gap-2">
              <textarea
                rows={8}
                className="w-full outline-none text-[1.6rem] border border-gray-400 px-5 py-3 font-medium rounded-lg "
                placeholder="Message"
              />
            </div>
          </form>
        </div>
      </section>

      <section className="layout-section bg-[#C79678]">
        <div className="max-width">
          <h2 className="text-center font-medium text-white md:text-[3.8rem] text-[2.5rem]">
            What Our Clients Says
          </h2>

          <div className="md:mt-20 mt-10">
            <Swiper
              loop={true}
              autoplay={{
                delay: 4000,
              }}
              pagination={{
                el: "#bullets-Portfolio",
              }}
              navigation={{
                prevEl: ".prev-btn",
                nextEl: ".next-btn",
              }}
              modules={[Pagination, Autoplay, Navigation]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {Clients.map((details) => {
                return (
                  <SwiperSlide>
                    <Card2 Details={details} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {/* swiper button and pagination customization***** */}
          </div>
        </div>
      </section>
    </>
  );
}
