import React from "react";
import { Card1 } from "../../../components/Cards/Card1";
import img1 from "../../../assets/Images/room-569d662a7fec15dfc0387f769580267f.png";
import img2 from "../../../assets/Images/room-d261ffdc7420fb563bf1f8d12eba617e.png";
import img3 from "../../../assets/Images/room-f10941f0799bfd7afbd9a273d050f4c6.png";
import img4 from "../../../assets/Images/room-5ba938b84f2fb8af197f2dfd568d98f0.png";

import electric from "../../../assets/svg/electric.svg";
import furniture from "../../../assets/svg/furniture.svg";

import "swiper/css";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Details = [
  {
    Image: img1,
    Heading: "Zen Villa",
    Para: "Where luxury meets nature's embrace – your tranquil 3BHK villa in Lonavala.",
    Price: 26000,
  },
  {
    Image: img2,
    Heading: "Amalfi Chalet",
    Para: "Escape to a serene 4BHK villa nestled in the heart of Lonavala's picturesque landscapes for an unforgettable retreat.",
    Price: 2600,
  },
  {
    Image: img3,
    Heading: "Ninad homestay",
    Para: "The Cottages are placed amidst sweeping mountainscapes, guaranteed to leave you spell-bound. It is complete with a private swimming pool overlooking serene views, Which is complemented by the delicate....",
    Price: 2500,
  },
  {
    Image: img4,
    Heading: "Blanco",
    Para: "Experience the epitome of luxury and relaxation at our exquisite Blancovilla, featuring a private pool and a lavish bathtub. Nestled in the heart of Lonavala, where you can escape the hustle and bustl....",
    Price: 1700,
  },
];

const PropertiesSection = () => {
  return (
    <div className="max-width">
      <h2 className="sm:text-[4.3rem] txt-black text-[2.5rem] font-medium">
        Feature Properties
      </h2>
      <h3 className="sm:text-[2.3rem] font-medium text-[1.6rem] txt-gray-primary open-sans-family">
        Pick a room that best suits your taste and budget
      </h3>
      <div className="md:mt-20 mt-10">
        <Swiper
          // loop={true}
          // autoplay={{
          //   delay: 4000,
          // }}
          pagination={{
            el: "#bullets-Portfolio",
          }}
          navigation={{
            prevEl: ".prev-btn",
            nextEl: ".next-btn",
          }}
          modules={[Pagination, Autoplay, Navigation]}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {Details.map((details) => {
            return (
              <SwiperSlide>
                <Card1 Details={details} />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {/* swiper button and pagination customization***** */}
      </div>
    </div>
  );
};

export default PropertiesSection;
