import React from "react";
import { FaStar } from "react-icons/fa";

const Card2 = ({ Details }) => {
  return (
    <div className="min-h-[362px] bg-white p-6 rounded-3xl">
      <div className="md:h-[340px] relative">
        <img src={Details.Image} alt="" className="w-full h-full rounded-md" />
        <div className="absolute bottom-0 left-0 bg-[#f4f0ea9c] px-7 py-2 text-[1.8rem] txt-black capitalize rounded-md">
          {Details.Name}
        </div>
      </div>
      <div className="mt-6">
        <div className="flex justify-between items-center">
          <h2 className="text-[1.8rem] font-normal txt-black capitalize">
            Property : <span className="text-[#8E7861]">{Details.Heading}</span>
          </h2>
          <p className="flex gap-3 items-center text-[1.7rem] font-normal txt-black open-sans-family">
            5 <FaStar className="text-[#8E7861]" />
          </p>
        </div>
        <hr className="mt-3 border border-gray-300" />
        <p className="md:text-[1.7rem] text-[1.4rem] txt-black font-normal capitalize mt-4 open-sans-family">
          {Details.Para}
        </p>
        <hr className="mt-3 border border-gray-300" />
        <p className="md:text-[1.6rem] text-[1.4rem] mt-4 open-sans-family">
          Posted On: Other
        </p>
      </div>
    </div>
  );
};

export default Card2;
