export const Nav_Links = [
  {
    linkName: "Home",
    src: "/",
    isArrow: false,
  },
  {
    linkName: "Property",
    isArrow: true,
    subLinks: [
      {
        subLinkName: "Coorg",
        src: "/Coorg",
      },
      {
        subLinkName: "Nashik",
        src: "/Nashik",
      },
      {
        subLinkName: "Goa",
        src: "/Coorg",
      },
      {
        subLinkName: "Khopoli",
        src: "/Coorg",
      },
      {
        subLinkName: "lonavala",
        src: "/Coorg",
      },
      {
        subLinkName: "Karjat",
        src: "/Coorg",
      },
    ],
  },
  {
    linkName: "About",
    src: "/About",
  },
  {
    linkName: "Contact us",
    src: "/Contact",
    isArrow: false,
  },
];
