import React from "react";
import AboutImg from "../../assets/Images/about.webp";

import place from "../../assets/svg/place.svg";
import view from "../../assets/svg/view.svg";
import privacy from "../../assets/svg/privacy.svg";
import booking from "../../assets/svg/booking.svg";
import support from "../../assets/svg/support.svg";
import valformoney from "../../assets/svg/valueformoney.svg";

import Book from "../../assets/Images/Book.png";

const ChooseUs = [
  {
    Heading: "A place for everyone",
    Para: "We believe in curating a space for everyone, which matches & compliment their style",
    Icon: place,
  },
  {
    Heading: "Dedicated support",
    Para: "Elevate your vacation experience featuring our dedicated support at every step by our team, ensuring your journey is seamless and stress-free.",
    Icon: support,
  },
  {
    Heading: "Value for money",
    Para: "Enjoy undiscounted services and amenities without compromising on your budget. ",
    Icon: valformoney,
  },
  {
    Heading: "Amazing View",
    Para: "We bring you a fantastic collection of private villas in tranquil locations that are ideal for every occasion.",
    Icon: view,
  },
  {
    Heading: "Home Like Privacy",
    Para: "Our goal is to provide you with an unforgettable experience , and ensure you have all the needed comforts to make you feel at home",
    Icon: privacy,
  },
  {
    Heading: "Easy Booking Process",
    Para: "We believe in superior service which makes your holiday exceptional through our personalized attention to detail.",
    Icon: booking,
  },
];

const About = () => {
  return (
    <>
      <section className="layout-section">
        <div className="max-width flex lg:flex-row flex-col items-center gap-12">
          <div className="lg:w-[60%] flex flex-col gap-5">
            <h2 className="md:text-[3.3rem] text-[2.5rem] font-medium text-[#303030]">
              At Mysa Stays
            </h2>
            <h3 className="text-[#5d5d5d] md:text-[2.3rem] text-[1.8rem] font-medium">
              Your Ideal Getaway Awaits
            </h3>
            <p className="text-[#808080] md:text-[1.6rem] text-[1.4rem] font-normal open-sans-family">
              At Mysa Stays, we see travel as an art of storytelling and believe
              that travel is about more than just seeing the sights. It's about
              connecting with new cultures, making lasting memories, and
              creating a lifetime of stories.
            </p>
            <p className="text-[#808080] md:text-[1.6rem] text-[1.4rem] font-normal open-sans-family">
              our story is all about weaving your narrative into the tapestry of
              unforgettable experience and our passion lies in curating those
              experiences for our guests through our extensive array of vacation
              rentals situated in various destinations
            </p>
            <p className="text-[#808080] md:text-[1.6rem] text-[1.4rem] font-normal open-sans-family">
              Mysa Stays is your gateway to the perfect travel experience.
            </p>
          </div>
          <div className="lg:w-[40%]">
            <img src={AboutImg} alt="About-Image" className="w-full h-auto" />
          </div>
        </div>
      </section>

      <section className="layout-section bg-[#EDEDED]">
        <div className="max-width ">
          <div className="flex flex-col items-center gap-3 justify-center">
            <h2 className="md:text-[4.3rem] text-[2.5rem] leading-[3rem] font-medium txt-black text-center">
              Experience Our Core Features
            </h2>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 md:mt-36 mt-10 md:gap-24 gap-16">
            {ChooseUs.map((details) => {
              return (
                <div className="flex md:items-start gap-4">
                  <div className="w-[140px] h-[60px]">
                    <img
                      src={details.Icon}
                      alt={details.Icon}
                      className="w-full h-full"
                    />
                  </div>
                  <div>
                    <h2 className="txt-black text-[2rem] font-medium">
                      {details.Heading}
                    </h2>
                    <p className="txt-gray-secondary text-[1.6rem] open-sans-family mt-2">
                      {details.Para}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section
        className="layout-section md:h-[500px] h-[350px] flex items-center"
        style={{
          backgroundImage: `url(${Book})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="flex flex-col md:gap-14 gap-8 items-center justify-center max-w-[900px] mx-auto">
          <h2 className="md:text-[4.8rem] text-[2.4rem] text-center md:leading-[5rem] leading-[3rem] text-white">
            Ready to Book? Reach Us Today for Instant Reservations!
          </h2>
          <div className="flex md:flex-row flex-col gap-4 text-white md:text-[2.1rem] text-[1.5rem] lora-family italic font-normal">
            <div>
              <p>Call Us : +91-9834554238 </p>
            </div>
            <span className="hidden md:block">/</span>
            <div>
              <p>Email : mysastays@gmail.com</p>
            </div>
          </div>

          <div>
            <button className="common-btn open-sans-family border-2 border-white md:!text-[1.8rem] !uppercase">
              Book Now
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
