import React from "react";
import { Link } from "react-router-dom";
import place from "../../assets/svg/place.svg";
import support from "../../assets/svg/support.svg";
import valformoney from "../../assets/svg/valueformoney.svg";
import view from "../../assets/svg/view.svg";
import privacy from "../../assets/svg/privacy.svg";
import booking from "../../assets/svg/booking.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Card2 from "../../components/Cards/Card2";

import clientImg1 from "../../assets/Images/clientimg1.png";
import clientImg2 from "../../assets/Images/clientimg2.png";
import clientImg3 from "../../assets/Images/clientimg3.png";
import clientImg4 from "../../assets/Images/clientimg4.png";

const Clients = [
  {
    Image: clientImg1,
    Heading: "Ohana",
    Para: `Villa was at the walking distance from lonavala station , The interior was very aesthetic, the pool was also clean and hygienic we also loved the service, care taker was available for every thing, we would also like to book villa from them in
    future.
    `,
    Name: "yash singh",
  },
  {
    Image: clientImg2,
    Heading: "Blanco",
    Para: `Really good villa, enjoyed our stay there. `,
    Name: "aditi",
  },
  {
    Image: clientImg3,
    Heading: "Zen Villa",
    Para: `Calm and beautiful villa I have ever been to. My kids and family enjoyed a lot. Swimming pool area is too good and clean.
    `,
    Name: "yash sharma",
  },
  {
    Image: clientImg4,
    Heading: "Amara Villa",
    Para: `The place was really nice, surrounded by nature and calm. Even though this villa doesn’t have a pool like other adjacent villas still we enjoyed a lot. Once you reach there the place was pleasant. Everything was clean.`,
    Name: "yash singh",
  },
];

const ChooseUs = [
  {
    Heading: "A place for everyone",
    Para: "We believe in curating a space for everyone, which matches & compliment their style",
    Icon: place,
  },
  {
    Heading: "Dedicated support",
    Para: "Elevate your vacation experience featuring our dedicated support at every step by our team, ensuring your journey is seamless and stress-free.",
    Icon: support,
  },
  {
    Heading: "Value for money",
    Para: "Enjoy undiscounted services and amenities without compromising on your budget. ",
    Icon: valformoney,
  },
  {
    Heading: "Amazing View",
    Para: "We bring you a fantastic collection of private villas in tranquil locations that are ideal for every occasion.",
    Icon: view,
  },
  {
    Heading: "Home Like Privacy",
    Para: "Our goal is to provide you with an unforgettable experience , and ensure you have all the needed comforts to make you feel at home",
    Icon: privacy,
  },
  {
    Heading: "Easy Booking Process",
    Para: "We believe in superior service which makes your holiday exceptional through our personalized attention to detail.",
    Icon: booking,
  },
];

export default function PartnerWithUS() {
  return (
    <>
      <section className="layout-section">
        <div className="max-width flex lg:flex-row flex-col gap-16 min-h-[600px]">
          <div className="lg:w-[60%]">
            <h2 className="md:text-[2.8rem] text-[2.5rem] font-medium">
              Join Hands with Mystays: Elevate Your Accommodation Offering
            </h2>
            <p className="text-[1.6rem] font-normal text-[#808080] open-sans-family">
              At Mysa Stays, we believe in the power of collaboration and mutual
              growth. We are excited to partner with accommodation providers who
              share our commitment to exceptional travel experiences,
              personalized service, and creating memorable moments for our
              guests.
            </p>
            <form
              action=""
              className="flex flex-col gap-6 mt-8 open-sans-family"
            >
              <div>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Name"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>
              <div>
                <input
                  type="number"
                  name=""
                  id=""
                  placeholder="Phone Number"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>
              <div>
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder="Email"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>
              <div>
                <textarea
                  type="text"
                  rows={8}
                  placeholder="Message"
                  className="w-full outline-none px-5 py-3 border border-gray-400 rounded-md text-[1.7rem]"
                />
              </div>

              <div>
                <button className="common-btn w-full !text-[1.8rem]">
                  Submit Now
                </button>
              </div>
            </form>
          </div>

          <div className="lg:w-[40%] flex flex-col gap-5 lg:mt-40">
            <h2 className="text-[3.3rem] font-medium">Get in touch</h2>
            <p className="text-[1.7rem] font-normal text-[#808080] open-sans-family">
              Office no 7 , second floor, gnp, galaxy industrial and commercial
              hub, Waldhuni, Ambernath, Ulhasnagar, Maharashtra 421501
            </p>
            <div className="text-[1.7rem] font-normal text-[#808080] open-sans-family">
              Call Us: <Link className="text-[#c79678]">+91-9834554238</Link>
            </div>
            <div className="text-[1.7rem] font-normal text-[#808080] open-sans-family">
              Email: <Link className="text-[#c79678]">mysastays@gmail.com</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-section">
        <div className="max-width">
          <div className="flex flex-col items-center gap-3 justify-center">
            <h2 className="md:text-[4.3rem] text-[2.5rem] font-medium txt-black">
              Benefits Of Partnering With Us
            </h2>
            <p className="md:text-[1.6rem] text-[1.4rem] text-center txt-gray-secondary">
              Unlock the True Potential of Your Holiday Home. A Partnership of
              Trust with mysa stays
            </p>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-20 md;gap-28 gap-10">
            {ChooseUs.map((details) => {
              return (
                <div>
                  <div className="w-[80px] mx-auto">
                    <img
                      src={details.Icon}
                      alt="client-images"
                      className="w-full h-full"
                    />
                  </div>
                  <h2 className="text-center txt-black md:text-[2.8rem] text-[2rem] font-medium">
                    {details.Heading}
                  </h2>
                  <p className="text-center txt-gray-secondary open-sans-family md:text-[1.6rem] text-[1.4rem] mt-3">
                    {details.Para}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="layout-section bg-[#C79678]">
        <div className="max-width">
          <h2 className="text-center font-medium text-white md:text-[3.8rem] text-[2.5rem]">
            What Our Clients Says
          </h2>

          <div className="md:mt-20 mt-10">
            <Swiper
              loop={true}
              autoplay={{
                delay: 4000,
              }}
              pagination={{
                el: "#bullets-Portfolio",
              }}
              navigation={{
                prevEl: ".prev-btn",
                nextEl: ".next-btn",
              }}
              modules={[Pagination, Autoplay, Navigation]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {Clients.map((details) => {
                return (
                  <SwiperSlide>
                    <Card2 Details={details} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {/* swiper button and pagination customization***** */}
          </div>
        </div>
      </section>
    </>
  );
}
