import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const TermsArr = [
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "cancellation",
    Para: `- If you wish to cancel 15days before the arrival date, we will refund 80%* of the booking value in the original payment mode or 100% in the form of a future credit note.
    - Cancellation between 7 to 15 days- It will be Non -Refundable, however we shall open the dates for the reservation (after clients consent) and find another in the same price post which we shall share a credit note. The difference will be borne by the guest if the alternate reservation value is more than the original reservation and if its less than the remaining amount will be refunded in the form of a credit note. Validity of the credit note will be 60 days from the day of issuance.
    For any cancellations requested within 7 days of the check-in date, the booking will be non-refundable.
    Our standard cancellation policy exempts cancellations made for peak dates public holidays and long weekends
    Bookings made via third-party sites, such as Airbnb or GoMMT, may be subject to the respective channel’s cancellation terms. Please check the Cancellation Policy of the site where the booking was originally made.
    
    Credit note –
    In case of any changes made to a reservation wherein the updated booking value exceeds the original amount, the difference must be borne by the guest.
    - Credit note will not be applicable for peak dates public holidays and long weekends
    - A Future Credit Note cannot be combined with another credit note.
    The validation period of a Future Credit Note will be specified on it and cannot be extended. If a booking is made using credit, the check-in date must be within the validity period.
    In most cases, a Future Credit Note expires 3 months from its date of issue, unless explicitly stated otherwise at the time of issue.
    A Future Credit Note cannot be combined with another credit note.
    If a booking has been made using credit, standard cancellation policy will not be applicable`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
  {
    Heading: "Acknowledgement",
    Para: `Mysa stays Private Limited is an online and offline platform for providing reasonable and temporary properties/ accommodations, operating in the brand name “mysa stays ”. Below Terms and Conditions are applicable to all the Guests or customers who are making bookings for villas or service apartments through any of our online channels like our website, our mobile application, third party website or third party application or through offline channels like any third parties, advertisements, referrals etc. Please read these Terms and Conditions carefully and ensure that you understand them before you start making a Booking through our Booking Channels. These Terms and Conditions becomes applicable to the Guests who make a Booking through any of the Booking Channels and also to the Guests making any queries related to any Booking made through our Booking Channels. These Terms and Conditions are subject to change and Mysa stays may, without prior notice to you and at any time, revise them and any other information contained in any Booking Channel by updating these Terms and Conditions. Please note that the restated Terms and Conditions shall be effective immediately upon posting. Mysa stays may also make improvements or changes in the services provided by it as described in Booking Channels at any time without notice. Therefore, you are advised to regularly check for any amendments or updates to these Terms and Conditions. Except where additional terms and conditions are provided, these Terms and Conditions supersede all previous representations, understandings or agreements and shall prevail notwithstanding any variance with any other terms of any order placed or service provided by mysa stays . If you are not agreeable to any of these Terms and Conditions, please do not use our Booking Channels to make a Booking through mysa stays`,
  },
];

const Terms = () => {
  return (
    <>
      <section className="layout-section">
        <h1 className="text-[5.3rem] font-medium txt-black text-center">
          Terms and Condition
        </h1>
        <div className="max-width flex flex-col !mt-44">
          {TermsArr.map((Data) => (
            <Accordin Details={Data} />
          ))}
        </div>
      </section>
    </>
  );
};

const Accordin = ({ Details }) => {
  const [open, setIsOpen] = useState(false);
  return (
    <div className="border border-gray-150 rounded-md cursor-pointer">
      <h2
        className="text-[1.9rem] text-[#212529] font-medium px-5 py-4 flex justify-between items-center open-sans-family capitalize"
        onClick={() => {
          setIsOpen(!open);
        }}
        style={{
          backgroundColor: open ? "#FFF5EE" : "",
          color: open ? "#CA9678" : "",
        }}
      >
        {Details.Heading}{" "}
        <span
          style={{
            rotate: open ? "180deg" : "0deg",
          }}
        >
          <IoIosArrowDown size={22} />
        </span>
      </h2>

      <div
        style={{
          maxHeight: open ? "1200px" : "0",
          overflow: "hidden",
          transition: "all 1s linear",
        }}
      >
        <pre
          className="text-[1.6rem] font-normal txt-black open-sans-family px-4 pb-4 mt-2"
          style={{
            whiteSpace: "pre-wrap",
            textAlign: "justify",
            padding: "1.6rem 2rem",
          }}
        >
          {Details.Para}
        </pre>
      </div>
    </div>
  );
};

export default Terms;
